<template>
  <div class="content">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <table class="table table-striped  align-middle">
      <thead>
        <th style="width: 15%">
          Id. Logística taxa
        </th>
        <th>
          Taxa
        </th>
      </thead>
      <tbody>
        <tr v-for="(taxa, index) in getTaxes" v-bind:key="index">
          <td>
            {{ taxa.id }}
          </td>
          <td>
            {{ taxa.nome }}
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="!this.observacaoList || observacaoList <= 0">
      Nenhuma observação informada
    </div>
    <div v-else>
      <div class="process-observacao" v-for="(item, index) in observacaoList" v-bind:key="index">
        <div>
          <span class="usuario">{{ $util.formatUser(item.email) }}</span>
          <span class="criado_em">{{ $util.formatDateTime(item.data) }}</span>
        </div>
        <div class="observacao" v-html="$util.nl2br(item.observacao)"></div>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'
// import ReportService from '@/services/ReportService'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ReportService from '@/services/ReportService'

export default {
  name: 'ReportAlertsProcessosTaxaClienteSemCobrancaDetails',
  data () {
    return {
      tipoAlerta: '09204',
      isLoading: false,
      fullPage: false,
      observacaoList: []
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object
    }
  },
  components: {
    Loading
  },
  created () {
    let _this = this
    _this.isLoading = true
    ReportService.getInfosAlert(_this.tipoAlerta, _this.rowData.IdLogistica_House).then(res => {
      this.observacaoList = res.data.data
    }
    ).finally(() => {
      _this.isLoading = false
    })
  },
  computed: {
    getTaxes () {
      let taxas = []

      if (this.rowData.taxas) {
        let taxaInfo = this.rowData.taxas.split(';')

        for (let i in taxaInfo) {
          let taxa = taxaInfo[i].split(',')
          taxas.push({
            id: taxa[0],
            nome: taxa[1]
          })
        }
      }

      return taxas
    }
  }
}
</script>

<style scoped>
.content {
  margin-top: 5px;
  margin-bottom: 20px;
}

.divergencia, .divergencia:hover {
  color: #aa6708;
  background-color: #fcf8e3;
}

.divergencia:hover {
  font-weight: bold;
}
.tax-table tr:hover td {
  font-weight: bold;
}
</style>

<style>
.process-observacao .usuario, .process-observacao .criado_em {
  font-size: 12px;
}
.process-observacao .criado_em {
  padding-left: 8px;
  font-size: 12px;
}

.process-observacao .observacao {
  padding: 5px 0 15px 0;
  font-size: 14px;
  padding-bottom: 25px;
}
</style>
